import type { SelfDescribingJson } from "@snowplow/browser-tracker";

type SnowplowClickPayloadProps = {
  readonly id: string;
  readonly classes: ReadonlyArray<string>;
  readonly url: string;
  readonly trackingId: string | null;
  readonly contentId: number;
};

export function snowplowClickPayload({
  id,
  classes,
  url,
  trackingId,
  contentId,
}: SnowplowClickPayloadProps): ReadonlyArray<SelfDescribingJson> {
  return [
    {
      data: {
        additional_data: [
          { key: "recommendation_api_response_id", value: trackingId },
          { key: "recommendation_api_content_id", value: contentId.toString() },
        ],
        classes,

        id,
        // We don't have any tracking labels, but we have to follow a JP schema
        tracking_labels: [],
        url,
      },
      schema: "iglu:dk.jyllands-posten/link_click/jsonschema/1-0-0",
    },
  ];
}
