import type { ImageLoader } from "next/image";

export function imageLoader(remoteImageDomain: string): ImageLoader {
  return ({ src, quality, width }) => {
    const encodeImageUrl = encodeURI(src);
    const qualityCommand = quality !== undefined ? `/quality:${quality}` : "";

    const resizeCommand = `/watchmedier/resize:fill:${width}:0:0`;
    const path = `${resizeCommand}/plain/${encodeImageUrl}${qualityCommand}`;

    return `${remoteImageDomain}${path}`;
  };
}
