"use client";

import { usePathname, useSearchParams } from "next/navigation";
import type { KeyboardEvent, MouseEvent } from "react";
import { useEffect, useState } from "react";
import { useSnowplowContext } from "@/contexts/snowplow/snowplow.context";
import { useUser } from "@/contexts/user/user.context";
import { useCookiebotConsent } from "@/hooks/cookiebot/use-cookiebot-consent.hook";
import { captureClientException } from "@/services/logging/capture-client-exception";
import { useJpmuid } from "../jpmuid/use-jpmuid.hook";
import { snowplowClickPayload } from "./snowplow-payload/snowplow-click-payload";
import { buildUserPayload } from "./snowplow-payload/snowplow-common-payload";

type SnowplowClickEventProps = {
  readonly id: string;
  readonly classes: ReadonlyArray<string>;
  readonly url: string;
  readonly trackingId: string | null;
  readonly contentId: number;
};

type ClickEvent = {
  readonly clickCallback: (event: MouseEvent<HTMLElement>) => void;
  readonly keypressCallback: (event: KeyboardEvent) => void;
};

export function useSnowplowClickEvent({
  id,
  classes,
  url,
  trackingId,
  contentId,
}: SnowplowClickEventProps): ClickEvent {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const consent = useCookiebotConsent();
  const { trackingData } = useSnowplowContext();
  const { jpmuid } = useJpmuid();
  const { model: user } = useUser();

  const [isClicked, setIsClicked] = useState<boolean>(false);

  const context = snowplowClickPayload({
    classes,
    contentId,
    id,
    trackingId,
    url,
  });

  const userPayload =
    trackingData !== null ? buildUserPayload(user, jpmuid) : null;

  useEffect(() => {
    async function trackClickEvent() {
      if (
        isClicked &&
        trackingId !== null &&
        consent.submitted &&
        trackingData !== null &&
        userPayload !== null
      ) {
        const { trackStructEvent } = await import(
          /* webpackChunkName: "snowplow-tracker" */ "@snowplow/browser-tracker"
        );
        trackStructEvent({
          action: "link_click",
          category: "user_activity",
          context: [...context, userPayload],
        });
      }
    }

    trackClickEvent().catch(captureClientException);
  }, [isClicked, trackingId, consent]);

  const clickCallback = (event: MouseEvent<HTMLElement>) => {
    const rightMouseButton = 2;
    if (event.button !== rightMouseButton) setIsClicked(true);
  };

  const keypressCallback = (event: KeyboardEvent) => {
    if (event.key === "Enter") {
      setIsClicked(true);
    }
  };

  useEffect(() => {
    // On any new page we reset the tracking
    setIsClicked(false);
  }, [pathname, searchParams]);

  return {
    clickCallback,
    keypressCallback,
  };
}
